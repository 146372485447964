.custom-card .card-img-top {
  height: 150px;
  object-fit: cover;
  width: 100%;
}

.card-body {
  display: flex;
  flex-direction: column;
}

.card-title {
  margin-top: auto;
  color: #000000;
  height: 3em;
}

.card-text {
  overflow: auto;
  flex-grow: 1;
}
.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  margin-top: 10px;
}

.card-title {
  color: #000000;
}
.custom-card {
  display: flex;
  flex-direction: column;
  height: 90%;
  margin-top: 20px;
}

.custom-card .card-img-top {
  height: 50%;
  object-fit: cover;
}

.custom-card .card-body .card-text {
  overflow: auto;
  flex-grow: 1;
}
.custom-card a {
  text-decoration: none;
  color: black;
}

.custom-card a:hover {
  text-decoration: none;
}
