.navbar-dark .navbar-nav .nav-link {
  color: white;
}
.banner-container {
  background-size: cover;
  background-position: center;
  height: 200px;
}
.responsive-logo {
  width: auto;
  height: 2.5rem;
}
.responsive-hide-phone {
  margin-right: 2rem;
}
@media (max-width: 768px) {
  .responsive-logo {
    height: 2rem;
  }
}
body {
  font-family: "Arial", sans-serif;
  color: #333;
  line-height: 1.6;
}
.text-primary {
  color: #007bff;
}

.text-secondary {
  color: #6c757d;
}

@media (max-width: 768px) {
  .navbar-nav .nav-link {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }

  .navbar .text-light {
    display: none;
  }
  .responsive-hide {
    display: none;
  }
}
@media (min-width: 768px) {
  .desktop-hide {
    display: none !important;
  }
}
@media (min-width: 769px) {
  .navbar .text-light {
    display: flex;
  }
}

@media (max-width: 1396px) {
  .responsive-hide-menu {
    display: none;
  }
}
@media (max-width: 1346px) {
  .responsive-hide-phone {
    display: none;
  }
}

@media (max-width: 991px) {
  .responsive-hide-phone {
    display: flex;
  }

  .responsive-hide-on-small {
    display: none !important;
  }
}
