.container {
  padding-top: 20px;
  padding-bottom: 40px;
}

h1.text-center {
  margin-bottom: 30px;
  color: #000000;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #000000;
}

.card {
  margin-bottom: 20px;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1200px) {
  .card-text {
    font-size: 0.9em !important;
  }
}
.card-title {
  color: #000000;
  font-size: 1.5em;
}

.card-text {
  font-size: 1em;
  line-height: 1.5;
  color: #333;
}

.card-body {
  padding: 20px;
}

.list-group {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.list-group-item {
  border: none;
  border-bottom: 1px solid #ddd;
}

.list-group-item:last-child {
  border-bottom: none;
}

.fc-icon {
  margin-right: 10px;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .card-title {
    font-size: 1.2em;
  }

  h1.text-center,
  h3 {
    font-size: 1.2em;
  }
}
