.navbar-dark .navbar-nav .nav-link {
  color: white;
}
.banner-container {
  background-size: cover;
  background-position: center;
  height: 200px;
}
body {
  font-family: "Arial", sans-serif;
  color: #333;
  line-height: 1.6;
}
.text-primary {
  color: #000000;
}

.text-secondary {
  color: #6c757d;
}

@media (max-width: 768px) {
  .navbar-nav .nav-link {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }

  .responsive-hide {
    display: none;
  }
}

@media (min-width: 769px) {
  .navbar .text-light {
    display: flex;
  }
}

.footer {
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-logo-col {
  justify-content: center;
}

.footer-logo {
  max-height: 100px;
}

.footer-nav .nav-link {
  color: white;
  text-align: center;
}

.footer-contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-contact-info span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-bottom-text {
  font-size: 0.9em;
  margin-top: 15px;
}
.footer-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer-contact-info a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}

.footer-contact-info a:hover {
  text-decoration: underline;
}

.footer-contact-info span {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.footer-contact-info .icon {
  margin-right: 0.5em;
}

.footer-contact-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
}

@media (max-width: 576px) {
  .footer-contact-info {
    justify-content: space-between;
  }
  .footer-contact-info a {
    justify-content: center;
    margin-bottom: 0.5em;
  }
}

@media (max-width: 768px) {
  .footer-contact-info {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-contact-info a,
  .footer-contact-info span {
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .footer-nav {
    text-align: center;
  }
  .footer-nav .nav {
    width: 100%;
  }
  .footer-nav .nav-link {
    text-align: center;
    justify-content: center;
    width: 100%;
  }

  .footer-logo-col {
    align-items: center;
  }
}
@media (min-width: 768px) {
  .desktop-hide {
    display: none;
  }
}
.no-underline-white-text {
  color: white;
  text-decoration: none;
}
