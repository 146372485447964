.scholarship-page {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.scholarship-page h1 {
  color: #000000;
  margin-bottom: 20px;
}

.scholarship-page p {
  font-size: 1.1em;
  line-height: 1.5;
}

.scholarship-page .card-title {
  margin-top: 15px;
  font-weight: bold;
}

.scholarship-page .card-text {
  margin-top: 10px;
}
.text-box {
  text-align: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f8f9fa;
  font-family: "Arial", sans-serif;
}

.text-description {
  color: #333;
  line-height: 1.6;
}

.contact-info {
  margin-top: 15px;
}

.phone-link,
.email-link {
  display: inline-block;
  margin-right: 15px;
  color: #0056b3;
  text-decoration: none;
}

.phone-link:hover,
.email-link:hover {
  text-decoration: underline;
}

.fas {
  margin-right: 5px;
}

.scholarship-page .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  padding: 10px 20px;
  font-size: 1.1em;
}

.scholarship-page .btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}
