h1 {
  color: #000000;
  margin-bottom: 20px;
}

.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.list-group-item {
  background-color: #f8f9fa; /* Light background for list */
  border: 1px solid #dee2e6; /* Border for list items */
}
