.fc-icon {
  margin-bottom: 15px;
}
.carousel-item img {
  height: auto;
  object-fit: fill;
}
.image-frame {
  border: 3px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  transition: transform 0.2s;
}

.image-frame:hover {
  transform: scale(1.05);
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
}
@media (max-width: 998px) {
  .carousel-caption {
    display: none;
  }
}
.resource-links {
  font-size: small;
  color: black;
  white-space: nowrap;
}

.resource-links a {
  text-decoration: none;
  color: black;
  margin-right: 10px;
}

.resource-links a:hover {
  text-decoration: underline;
}

.resource-links span {
  margin: 0 5px;
}
@media (max-width: 985px) {
  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .resource-links span {
    display: block;
  }

  .resource-link {
    display: block;
    margin-bottom: 10px;
  }

  .container {
    padding: 10px;
  }
}
.welcome-message {
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  margin: 0 auto;
  font-size: 1.5em;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
